/* Font Awesome */
@import "~@fortawesome/fontawesome-free/css/all.css";

/* Bootstrap */
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import "bootstrap-icons/font/bootstrap-icons.css";

@font-face {
  font-family: "Novel Sans Pro Bold";
  src: url("../public/novel-sans-pro-bold.otf") format("opentype");
}

:root {
  --accent: #fe3b7d;
  --background: #e11962;
  --white: #f9f4fa;
  --white2: #fff;
  --black: #000;
  --primary: #fe3b7d;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
}

body {
  font-family: "Novel Sans Pro Bold", sans-serif;
  background-color: var(--white);
}

.content {
  margin-top: 100px;
  padding: 50px;
}

.txt-hoge-vucht {
  color: var(--accent);
}

.txt-white,
.txt-white a,
.txt-white p,
.txt-white h1,
.txt-white h2,
.txt-white h3,
.txt-white h4,
.txt-white h5,
.txt-white h6 {
  color: var(--white);
}

a.navbar-brand,
a.link,
a.page-link {
  text-decoration: none;
  color: var(--accent);
  transition: 1s;
}

a.navbar-brand:hover,
a.link:hover,
a.page-link:hover {
  text-decoration: underline;
  color: var(--background);
  cursor: pointer;
  transform: scale(1.05);
}

.bg-hoge-vucht {
  background-color: var(--background);
}

.bg-hoge-vucht::selection {
  color: var(--accent);
  background: var(--white);
}

.bg-hoge-vucht ::selection {
  color: var(--accent);
  background: var(--white);
}

::selection {
  color: var(--white);
  background: var(--accent);
}

.position-relative {
  position: relative;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.btn-primary {
  background-color: var(--accent) !important;
  border: none;
  border-radius: 25px;
  transition: 1s;
}

.btn-primary.round {
  border-radius: 100% !important;
  height: 50px;
  width: 50px;
}

.btn-primary:hover {
  background-color: var(--background) !important;
  scale: 1.1;
}

.form-control {
  background-color: var(--white2);
  border-radius: 25px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-5 {
  margin-left: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-100 {
  margin-top: 100px;
}

img {
  user-select: none;
}

.no-user-select {
  user-select: none;
}

.badge {
  height: fit-content;
}

.badge-primary {
  background-color: var(--primary);
  color: var(--white);
  border-radius: 25px;
}

.badge-secondary {
  background-color: var(--secondary);
  color: var(--white);
  border-radius: 25px;
}

.badge-success {
  background-color: var(--success);
  color: var(--white);
  border-radius: 25px;
}

.badge-warning {
  background-color: var(--warning);
  color: var(--white);
  border-radius: 25px;
}

.badge-danger {
  background-color: var(--danger);
  color: var(--white);
  border-radius: 25px;
}

/* Navbar */
.navbar {
  height: 75px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.navbar-brand {
  color: var(--white) !important;
}

.navbar-brand:hover {
  color: var(--white) !important;
}

.dropdown-toggle {
  color: var(--white);
  transition: 1s;
}

.dropdown-toggle:hover .toggle {
  scale: 1.05;
}

.language-dropdown {
  position: absolute;
  z-index: 1;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.language:hover {
  text-decoration: underline;
}

.logo {
  width: 150px;
  margin-top: 75px;
  border: 5px solid var(--white);
  border-radius: 50%;
  user-select: none;
  transition: 2s;
}

.logo:hover {
  transform: scale(1.05) rotate(5deg);
}

/* Home */
.carousel {
  padding-bottom: 75px;
}

.carousel img {
  border-radius: 25px;
}

.carousel-caption {
  text-shadow: -1px 0 var(--black), 0 1px var(--black), 1px 0 var(--black), 0 -1px var(--black);
  padding: 20px;
  border-radius: 25px;
  top: 50%;
  transform: translateY(-50%);
  bottom: initial;
  left: 50%;
  transform: translate(-50%, -50%);
  right: initial;
}

.feature-box {
  padding: 25px;
  box-shadow: 3px 3px 10px var(--black);
  border-radius: 25px;
  background-color: var(--white2);
  height: 100%;
  margin-left: 5px;
  margin-right: 5px;
  transition: 1s;
}

.feature-box:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.feature-box img {
  max-height: 200px;
  border-radius: 25px;
  display: block;
  margin: auto;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--black);
  color: var(--white);
  width: 50px;
  height: 50px;
}

/* Activities */
.map {
  width: 100%;
  min-height: 200px;
}

.details-image {
  height: fit-content;
  border-radius: 25px;
}

.pagination {
  justify-content: start !important;
}

.page-item.active .page-link {
  color: var(--white);
  border: var(--accent);
  background-color: var(--accent);
}

.page-item.active:hover .page-link {
  background-color: var(--background);
}

.page-item .page-link {
  color: var(--accent);
}

.card-box {
  text-decoration: none;
  color: var(--black);
}

/* Doughnut */
.no-bullets {
  list-style-type: none;
}

.section {
  padding: 25px 0;
}

.start-section {
  padding-bottom: 25px;
}

.end-section {
  padding-top: 25px;
}

/* Footer */
.copyright {
  padding: 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
